<template>
  <div v-if="articles?.length" class="relative">
    <MainPageBlockWrapperNew title="ЛЕТНИЕ ИСТОРИИ" href="/summer">
      <ClientOnly>
        <div>
          <Swiper
            :breakpoints="breakpoints"
            :slides-per-view="1"
            :space-between="20"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(item, index) in articles"
              :key="item.id || index"
              class="swiper-slide"
            >
              <MainPageCardsReworkedBaseCard
                :data="item"
                :title-length="85"
                disable-announce
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </ClientOnly>

      <SlidersSliderControl
        type="prev"
        class="absolute left-[-16px] top-[55%] z-10 hidden md:block"
        :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
        @click="slidePrev()"
      />
      <SlidersSliderControl
        type="next"
        class="absolute right-[-16px] top-[55%] z-10 hidden md:block"
        :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
        @click="slideNext()"
      />
    </MainPageBlockWrapperNew>
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

const { onSwiper, slideNext, slidePrev, isBeginning, isEnd } = useSwiper();
const breakpoints = {
  1024: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 4,
  },
};

const { summerArticles } = useSummerArticles();
const articles = computed(() => summerArticles?.data?.value);
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
