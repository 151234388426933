<template>
  <div class="relative flex h-full flex-col">
    <div class="mb-[20px] flex items-center justify-between gap-[20px]">
      <h2
        class="font-roboto text-[32px] font-medium leading-[38px] text-[#1f1f1f]"
        @click="handleTitleClick"
      >
        {{ title }}
      </h2>
      <Component
        :is="linkComponent.component"
        v-if="!isLessThanHorizontalPad && href"
        v-bind="linkComponent.props"
        class="text-[18px] font-medium leading-[22px] text-[#1f1f1f]"
      >
        Смотреть все
      </Component>
    </div>

    <slot></slot>
  </div>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  href: {
    type: String,
    default: '',
  },

  isExternalLink: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const { isLessThanHorizontalPad } = useBreakpoint();

const linkComponent = computed(() => {
  if (props.isExternalLink) {
    return { component: 'a', props: { href: props.href, target: '_blank' } };
  }

  return { component: NuxtLink, props: { to: props.href } };
});

const handleTitleClick = () => {
  if (!props.href || !isLessThanHorizontalPad.value) {
    return;
  }

  if (props.isExternalLink) {
    window.open(props.href, '_blank');
    return;
  }

  router.push(props.href);
};
</script>

<style lang="sass" scoped></style>
