<template>
  <MainPageBlockWrapperNew title="События" href="/read/news">
    <div class="-m-2.5 flex flex-wrap">
      <MainPageCardsReworkedNewsCard
        v-for="(item, index) in slicedNews"
        :key="item.id || index"
        class="w-full p-2.5 md:w-[50%] xl:w-[33.33333%]"
        :data="item"
        :title-length="50"
      />
    </div>
  </MainPageBlockWrapperNew>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
    require: true,
  },
});

const { isDesktop } = useBreakpoint();

const slicedNews = computed(() => {
  return isDesktop.value ? [...props.data].slice(0, 3) : props.data;
});
</script>
