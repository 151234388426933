<template>
  <div class="relative">
    <a
      href="https://историяотпервоголица.рф"
      target="_blank"
      class="mb-[20px] block font-roboto text-[32px] font-medium leading-[38px] text-[#1f1f1f]"
    >
      История от первого лица. Проект Фонда Андрея Кончаловского
    </a>

    <ClientOnly>
      <div>
        <Swiper
          :breakpoints="breakpoints"
          :slides-per-view="1"
          :space-between="20"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="(item, index) in videos"
            :key="item.id || index"
            class="swiper-slide"
          >
            <MainPageCardsReworkedVideoCard
              :data="item"
              @open:video="handleOpenVideo(item)"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </ClientOnly>

    <SlidersSliderControl
      type="prev"
      class="absolute left-[-16px] top-[50%] z-10 hidden md:block"
      :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
      @click="slidePrev()"
    />
    <SlidersSliderControl
      type="next"
      class="absolute right-[-16px] top-[50%] z-10 hidden md:block"
      :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
      @click="slideNext()"
    />
  </div>
</template>

<script setup>
import 'swiper/css';

import { Fancybox } from 'fancyapps-ui';
import { Swiper, SwiperSlide } from 'swiper/vue';

import konchalovskyFound from '@/assets/data/konchalovsky-found';
import { getYoutubePreview } from '@/services/helpers';

const { onSwiper, slideNext, slidePrev, isBeginning, isEnd } = useSwiper();

const breakpoints = {
  1024: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 4,
  },
};

const videos = computed(() => {
  return konchalovskyFound.map((video) => {
    const preview = getYoutubePreview(video.video_code);
    return { ...video, image: { preview } };
  });
});

const handleOpenVideo = (item) => {
  new Fancybox([
    {
      src: item.video_code,
      caption: item.title,
      type: 'iframe',
      preload: false,
      Html: {
        autoSize: false,
      },
      customClass: 'my-iframe',
    },
  ]);
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
