<template>
  <NuxtLink
    :to="getUrl"
    class="group relative flex rounded-[12px] text-white-100"
  >
    <MainPageCardsReworkedCommonCardImageBlock
      v-bind="{ data, disableLabel }"
      class="w-full"
    >
      <template #badge>
        <div class="flex gap-[20px] py-[10px] pr-[38px]">
          Материалов: {{ data.count }}
        </div>
      </template>
    </MainPageCardsReworkedCommonCardImageBlock>

    <div
      class="absolute top-0 mt-auto flex h-full flex-col justify-end p-[20px] font-roboto"
    >
      <div v-if="getAuthorName" class="z-30 mb-[8px] flex items-center">
        <div
          class="mr-[8px] h-[12px] w-[12px]"
          :class="getDataByModel?.labelBgColor"
        />
        <p class="text-[14px] font-bold leading-[17px]">
          {{ getAuthorName }}
        </p>
      </div>

      <h2
        class="z-30 text-[18px] font-medium 2xl:text-[24px] 2xl:leading-[29px]"
      >
        {{ data.title }}
      </h2>

      <p
        v-if="isDesktop"
        class="z-30 mt-[12px] text-[16px] font-normal 2xl:text-[20px] 2xl:leading-[28px]"
      >
        {{ shrinkText(truncTags(data.announce), 150) }}
      </p>
    </div>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
    require: true,
  },
  disableLabel: { type: Boolean, default: false },
});

const getCardSettings = useCardSettings();
const { isDesktop } = useBreakpoint();
const { shrinkText, truncTags } = useUtils();

const getDataByModel = computed(() => {
  return getCardSettings(props.data);
});

const getUrl = computed(() => {
  return (
    props.data?.url ?? `${getDataByModel.value?.hrefBase}${props.data?.slug}`
  );
});

const getAuthorName = computed(() => {
  const author = props.data?.authors?.[0];
  if (!author) return '';

  return `${author.firstname} ${author.surname}`;
});
/*
import CardImageBlock from '@/components/MainPage/CardsReworked/Common/CardImageBlock';
import getCardSettings from '@/mixins/getCardSettings';
import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';

export default {
  components: { CardImageBlock },

  mixins: [getCardSettings, getScreenBreakpoint],

  props: {
    data: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    disableLabel: { type: Boolean, default: false },
  },

  computed: {
    getDataByModel() {
      return this.getCardSettings(this.data);
    },

    getUrl() {
      return (
        this.data?.url ?? `${this.getDataByModel?.hrefBase}${this.data?.slug}`
      );
    },

    getAuthorName() {
      const author = this.data?.authors?.[0];
      if (!author) return '';

      return `${author.firstname} ${author.surname}`;
    },
  },
};*/
</script>
