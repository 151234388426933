<template>
  <div>
    <h2
      class="mb-[20px] font-roboto text-[32px] font-medium leading-[38px] text-[#1f1f1f]"
    >
      <NuxtLink to="/read/articles/zhurnal-ideologiya-budushchego">
        Журнал «Идеология будущего»
      </NuxtLink>
    </h2>

    <div class="relative">
      <ClientOnly>
        <Swiper
          :breakpoints="breakpoints"
          :slides-per-view="1"
          :space-between="20"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="(project, index) in specialProjects" :key="index">
            <MainPageCardsReworkedBaseCard
              :aspect="{ w: 1, h: 1 }"
              :data="project"
              position-image="object-top"
              :is-blank="project.isBlank"
              disable-label
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>

      <SlidersSliderControl
        type="prev"
        class="absolute left-[-16px] top-45% z-10 hidden md:block"
        :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
        @click="slidePrev()"
      />
      <SlidersSliderControl
        type="next"
        class="absolute right-[-16px] top-45% z-10 hidden md:block"
        :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
        @click="slideNext()"
      />
    </div>
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

import journals from '@/assets/data/special-projects/journal';

const { onSwiper, slideNext, slidePrev, isBeginning, isEnd } = useSwiper();

const specialProjects = computed(() => {
  return journals.gallery.map((item) => {
    return {
      model_type: 'specialproject',
      slug: item.link,
      image: {
        preview: `/images/projects/other/${item.previewSrc}`,
      },
      title: item.label,
      announce: '',
      isBlank: true,
    };
  });
});

const breakpoints = {
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
